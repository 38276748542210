<template>
  <div class="yfmbrout_heard">
    <!-- 区域选择dialog -->
    <el-dialog title="区域选择" :visible.sync="cityDialog" width="672px" :before-close="handleClose">
      <TreeTransfer ref="transferRef" :title="['区域选择', `已选择 ${0} 个区域`]" :from_data="transferInfo.fromData"
        :to_data="transferInfo.toData" :defaultProps="{ label: 'name', children: 'list' }" pid="parent_id" height="540px"
        :filter="false">
        <template v-slot:from>
          <el-input v-model="transferInfo.name" placeholder="搜索"></el-input>
        </template>

        <template v-slot:to>
          <el-input v-model="transferInfo.name" placeholder="搜索"></el-input>
        </template>
      </TreeTransfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleTransferCancel">取 消</el-button>
        <el-button type="primary" @click="handleTransferSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- back head -->
    <div @click="fhubtn" class="textmessage_top">
      <span> <i class="el-icon-arrow-left"></i> 返回 / </span>
      <span>{{ titles }}</span>
    </div>
    <!-- content -->
    <div class="textmessage_bottom">
      <div class="textmessage_bottom_text">
        <p>{{ titles }}</p>
        <!-- <span>提供专业、快速、低成本的短信平台发送和短信接口，满足各类企业需求</span> -->
      </div>
      <div class="textmessage_bottom_form">
        <div class="textmessage_bottom_formfy_01">
          <div class="d-flex align-items-center exemption">
            <p>包邮金额</p>
            <div class="exemption_input">
              <el-input v-model="form.price" placeholder="请输入包邮金额" type="number"></el-input>
            </div>
          </div>
          <el-table class="tabile01" :data="form.able_delivery_areas" style="width: 100%">
            <el-table-column label="包邮区域" min-width="500">
              <template slot-scope="scope">
                <span>{{
                  addressResourceMap2String(
                    scope.row.city
                  )
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150" align="right">
              <template slot-scope="scope">
                <div class="caozuo">
                  <a @click="handleEnabledModyfy(scope)" v-if="hasPerm(['delivery.rules.update'])">编辑</a>
                  <a @click="handleEnabledDelete(scope)" v-if="hasPerm(['delivery.rules.destroy'])">删除</a>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="btnbtn">
          <el-button @click="btnbclick" icon="el-icon-circle-plus-outline">
            添加包邮地区
          </el-button>
        </div>
      </div>
    </div>
    <diy-footer-button v-if="id
      ? form.is_default
        ? hasPerm(['delivery.rules.set_default'])
        : hasPerm(['delivery.rules.update'])
      : hasPerm(['delivery.rules.store'])
      ">
      <el-button type="primary" @click="handleSave">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
import TreeTransfer from "el-tree-transfer"; // 引入
import { Message } from "element-ui";
export default {
  components: {
    TreeTransfer,
  },
  data() {
    return {
      transferInfo: {
        fromData: [],
        toData: [],
        type: "", //辨别操作类型，enabled:选择配送 disabled:不配送
      },
      form:
      {
        price: "", //包邮金额
        city: [], //允许配送地址
        able_delivery_areas: [],
        disable_delivery_areas: [],
      },
      cityDialog: false,
      isModifyAdd: false, //是否是编辑时添加
      modifyAddIndex: -1,
      modifyConcatResource: [],

      rules: {
        rule_name: [
          {
            required: true,
            message: "请输入模板名称",
            trigger: "blur",
          },
        ],
      },
      id: this.$route.query.id,
      titles: "新增包邮规则",
      cityListAll: [],	//所有的地址信息
    };
  },
  created() {
    this.getAddressListInfo();
    if (this.$route.query.id) {
      this.getRulesInfoOfId();
      this.titles = "编辑包邮规则";
    } else {
      this.titles = "新增包邮规则";
    }
  },
  mounted() { },
  methods: {
    // 将省市区数据转为地址拼接
    addressResourceMap2String(addArray) {
      return addArray.reduce((pre, item, index) => {
        pre += `${item.name} ${(item.list &&
          item.list.length > 0 &&
          "(" + this.addressResourceMap2String(item.list) + ")") ||
          ""
          } `;
        return pre;
      }, "");
    },

    // 清空
    empty() {
      this.btnr == 0;
    },
    fhubtn() {
      this.$router.push("/setup/orparcel");
    },

    // 截取数据到市
    subResource2City(resource) {
      return resource.map((item) => {
        if (item.hasOwnProperty("list") && item.list.length > 0) {
          item.list = [
            ...item.list.map((ite) => {
              if (item.hasOwnProperty("list")) {
                // delete ite['list'];
                ite["list"] = [];
                ite["isLast"] = true;
                return ite;
              } else {
                return ite;
              }
            }),
          ];
          return item;
        }
      });
    },
    // 地址映射
    mapAddress(arr) {
      const result = arr.map((item) => {
        item.parent_id = 0;
        return item;
      });
      return result;
    },
    // 获取省市区数据
    getAddressListInfo() {
      this.$get(this.$apis.cityList).then((res) => {
        if (res.code != 200) {
          return Message.error(res.message);
        }
        this.cityListAll = res.data;
        this.$set(
          this.transferInfo,
          "fromData",
          this.mapAddress(this.subResource2City(res.data))
        );
      });
    },
    // 配送
    btnbclick() {
      this.transferInfo.type = "enabled";
      if (this.$route.query.id) {
        this.isModifyAdd = true;
      }
      this.cityDialog = true;
    },
    // 取消
    handleTransferCancel() {
      this.$refs["transferRef"]["clearChecked"]();
      this.$set(this.transferInfo, "toData", []);
      this.cityDialog = false;
      this.modifyAddIndex = -1;
    },
    handleClose() {
      this.handleTransferCancel();
    },
    // 提交
    handleTransferSubmit() {
      console.log(this.transferInfo.toData)
      switch (this.transferInfo.type) {
        case "enabled":
          const enabledObj = {
            city: [],
          };
          // 判断是否选择了地址
          if (this.transferInfo.toData.length == 0) {
            return Message.warning("请选择包邮地址!");
          }
          enabledObj["city"] = this.transferInfo.toData;
          if (this.$route.query.id && this.isModifyAdd) {
            this.form.able_delivery_areas.push(enabledObj);
          } else if (this.$route.query.id && this.modifyAddIndex >= 0) {
            this.$set(
              this.form.able_delivery_areas[this.modifyAddIndex],
              "city",
              this.transferInfo.toData
            );
          } else {
            this.form.able_delivery_areas.push(enabledObj);
          }
          break;
        case "disabled":
          const disabledObj = {
            province_list: [],
          };
          disabledObj["province_list"] = this.transferInfo.toData;
          this.$route.query.id
            ? (this.form.disable_delivery_areas = Array.of(disabledObj))
            : this.form.disable_delivery_areas.push(disabledObj);
          break;
      }
      this.handleTransferCancel();
    },
    // 保存
    handleSave() {
      if (!this.form.price) {
        this.$message({
          type: "warning",
          message: "请输入包邮金额",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false
      };
      let city = [];
      this.form.able_delivery_areas.forEach((item) => {
        item.city.forEach(i => {
          city.push(i)
        })
      })
      let data = {
        price: this.form.price,
        city: city,
      };
      this[(() => (this.$route.query.id ? "$put" : "$post"))()](
        `${this.$route.query.id
          ? this.$apis.parcelapi + "/" + this.$route.query.id
          : this.$apis.parcelapi
        }`,
        data
      ).then((res) => {
        if (res.code == 200) {
          Message.success({
            message: `${this.$route.query.id ? "编辑" : "新建"
              }包邮规则成功!`,
            onClose: () => {
              this.$router.push("/setup/orparcel");
            },
          });
          this.handleTransferCancel();
        } else {
          Message.error(res.message);
        }
      });
    },
    // 通过id获取详情
    getRulesInfoOfId() {
      this.$get(`${this.$apis.loopparce}/${this.$route.query.id}`).then(
        (res) => {
          if (res.code != 200) {
            return Message.error(res.message);
          }
          this.$nextTick(() => {
            let city = [{
              city: res.data.city
            }]
            this.$set(this, "form", res.data);
            this.$set(this.form, 'able_delivery_areas', city)
            // 点击编辑后，需要将可配送数据与不可配送数据进行结合，做到互斥效果
            // 1.获取可配送的数据
            for (const value of res.data.city) {
              this.$set(
                this,
                "modifyConcatResource",
                this.modifyConcatResource.concat(value)
              );
            }
            // 通过 modifyConcatResource 先动态生成 fromData
            const ids = this.getRightDataIds(this.modifyConcatResource, []);
            if (ids.length) {
              this.$set(
                this.transferInfo,
                "fromData",
                this.filterLeftData(this.transferInfo.fromData, ids, [])
              );
            }
          });
        }
      );
    },

    // 获取右侧数据 id
    getRightDataIds(list, rightIds) {
      for (let rightItem of list) {
        rightIds.push(rightItem.id);
        if (rightItem.list && rightItem.list.length) {
          this.getRightDataIds(rightItem.list, rightIds);
        }
      }
      return rightIds;
    },
    // 处理过滤数据
    getFilterLeftData() {
      let rightIds = this.getRightDataIds(this.transferInfo.toData, []);
      this.transferInfo.fromData = this.filterLeftData(
        this.transferInfo.fromData,
        rightIds,
        []
      );
    },

    filterLeftData(list, rightIds, newList) {
      for (let leftItem of list) {
        if (rightIds.includes(leftItem.id)) {
          if (leftItem.list && leftItem.list.length) {
            let insetBool = false;
            for (let child of leftItem.list) {
              if (!rightIds.includes(child.id)) insetBool = true;
            }
            if (insetBool) {
              let jsonItem = JSON.parse(JSON.stringify(leftItem));
              jsonItem.list = [];
              newList.push(jsonItem);
              this.filterLeftData(leftItem.list, rightIds, jsonItem.list);
            }
          }
        } else {
          let jsonItem = JSON.parse(JSON.stringify(leftItem));
          // jsonItem.list = [];
          newList.push(jsonItem);
        }
      }
      return newList;
    },

    // 配送编辑
    handleEnabledModyfy({ row, $index }) {
      this.transferInfo.type = "enabled";
      this.cityDialog = true;
      this.modifyAddIndex = $index;
      this.$set(this.transferInfo, "toData", row["city"]);
    },
    // 可配送删除
    handleEnabledDelete({ row, $index }) {
      let arr = [];
      this.form.able_delivery_areas.splice($index, 1);
      for (const value of this.form.able_delivery_areas) {
        arr = [...arr, ...value['city']];
      }
      for (const value of this.form.disable_delivery_areas) {
        arr = [...arr, ...value];
      }
      // 通过 modifyConcatResource 先动态生成 fromData
      const ids = this.getRightDataIds(arr, []);
      this.$set(
        this.transferInfo,
        "fromData",
        this.filterLeftData(this.cityListAll, ids, [])
      );
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table__header {
  width: 100% !important;
}

.textmessage_bottom_formfy_01 /deep/ .el-table__empty-block {
  width: 100% !important;
}

.yfmbrout_heard {
  border-bottom: none;

  & /deep/.el-dialog__header {
    border-bottom: none;
  }

  & /deep/.el-dialog__footer {
    border: none;
  }

  & /deep/.el-dialog__body {
    padding: 0 32px !important;
    box-sizing: border-box;
  }

  // transfer css
  & /deep/.transfer-left,
  /deep/.transfer-right {
    width: 280px;
    // height: 450px;
    background: #ffffff;
    border-radius: 3px;
    // border: 1px solid #DDDFE6;
    border: none;

    & .transfer-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: flex;
      align-items: center;
      background-color: #fff;
      border: none;

      & .el-checkbox {
        display: none;
      }
    }

    & .transfer-main {
      width: 100%;
      border: 1px solid #ebeef5;

      & .el-tree {
        margin-top: 10px;
      }

      & .el-tree-node {
        min-height: 38px;

        & .el-tree-node__content {
          width: 100%;
          min-height: 38px !important;
        }
      }

      & .el-tree-node__expand-icon {
        font-size: 14px;
        // background: #FFFFFF;
        color: rgba(0, 0, 0, 0.6);
      }

      & .custom-tree-node {
        // width: 42px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
      }

      & .el-checkbox {
        position: absolute;
        right: 0;
      }
    }
  }

  & /deep/.transfer-center {
    width: 48px;
    left: 46%;

    & button {
      width: 24px;
      height: 24px;
      background: #eeeeee !important;
      border: 1px solid #dcdcdc !important;
      border-radius: 4px;
      padding: 0 !important;

      & i::before {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }

  .textmessage_top {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;

    span:first-child {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }

  .textmessage_bottom {
    padding: 32px;
    border-radius: 4px;
    background: #fff;
    box-sizing: border-box;

    .textmessage_bottom_text {
      p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #000;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .textmessage_bottom_form {
      & /deep/.el-form {
        clear: both;

        & .el-form-item {
          margin-bottom: 32px;

          & .el-form-item__label {
            padding: 0 8px 0 0;
          }
        }
      }

      .btnbtn {
        margin-top: 20px;
        margin-left: 100px;

        .dtbutton {
          border: none;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: var(--primary);
        }
      }

      // border: 1px solid;
      padding: 40px 0px 0 0px;

      .textmessage_bottom_formfy_01 {
        /deep/.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
          background: #535353;
        }

        /deep/.el-table--scrollable-x .el-table__body-wrapper {
          background: transparent;
        }

        // display: flex;
        // margin-top: 32px;

        /deep/ .el-table th.el-table__cell {
          background-color: #f9f9fc;
          color: #333;
        }

        .el-input-number {
          width: 120px;
          // height: 32px;
        }

        // /deep/ .el-input {
        //   width: 120px;
        // }

        /deep/.el-input__inner {
          -webkit-appearance: none;
          background-color: #fff;
          background-image: none;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          color: #606266;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          outline: 0;
          padding: 0 15px;
          transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          //   width: 120px;
          //   text-align: center;
        }

        // margin-left: 24px;
        span {
          margin-left: 6px;
          font-size: 14px;
          color: #606266;
        }

        .tabile01 {
          // margin: -13px 0 0 10px;
        }
      }

      .aaabpsqy {
        margin-top: 20px;
        margin-left: 15px;
        display: flex;
        align-items: center;

        & span:nth-of-type(1) {
          flex-shrink: 0;
        }

        .bpsqy {
          border: 1px solid #dddfe6;
          width: 100%;
          min-height: 38px;
          border-radius: 3px;

          span {
            line-height: 38px;
            margin-left: 16px;
          }

          span:nth-last-child(2) {
            color: var(--fontColor);
            cursor: pointer;
          }

          span:nth-last-child(1) {
            color: var(--fontColor);
            cursor: pointer;
          }

          & .city-name {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333 !important;
          }
        }

        span {
          width: 90px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;

    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
    }
  }
}

.caozuo a {
  padding: 0 10px;
  border-right: 1px solid var(--fontColor, #fb6638);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  color: var(--fontColor);
}

.caozuo a:last-child {
  border-right: none;
}

.exemption {
  margin-bottom: 20px;

  p {
    font-size: 16px;
    margin-right: 20px;
  }

  .exemption_input {
    /deep/.el-input {
      width: 200px;

      .el-input__inner {
        width: 100%;
      }
    }
  }
}
</style>
